<template>
    <div class="loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
</template>

<script>
export default {
	name: 'LoaderQuad',
};
</script>

<style lang="scss">
.loader {
    display: grid;
    grid-template-columns: repeat(3, 16px);
    grid-gap: 4px;
    width: max-content;
    height: max-content;
}

.loader > div:not(:nth-child(5)) {
    width: 16px;
    height: 16px;
    background: $neutrals2;
    opacity: 0.1;
    animation: loader 1.6s linear infinite;
}

.loader div:nth-child(1) { animation-delay: 0s; }
.loader div:nth-child(2) { animation-delay: 0.2s; }
.loader div:nth-child(3) { animation-delay: 0.4s; }
.loader div:nth-child(6) { animation-delay: 0.6s; }
.loader div:nth-child(9) { animation-delay: 0.8s; }
.loader div:nth-child(8) { animation-delay: 1.0s; }
.loader div:nth-child(7) { animation-delay: 1.2s; }
.loader div:nth-child(4) { animation-delay: 1.4s; }

@keyframes loader {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    40% {
        opacity: 0.1;
    }
    100% {
        opacity: 0;
    }
}
</style>
